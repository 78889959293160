<template>
  <div>
    <b-button-group size="sm">
      <b-button variant="outline-primary" @click="goToDetail()">
        <span class="align-middle">Ver Clima</span>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import { BButtonGroup, BButton, BModal, BCardText, BRow, BCol, BDropdown, BDropdownItem } from "bootstrap-vue";

import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import BrunaElementLoader from '@/views/brunacomponents/BrunaElementLoader.vue'

import useApiServices from '@/services/useApiServices.js';
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BButtonGroup,
    BButton,
    BModal,
    BCardText,
    BDropdown,
    BDropdownItem,
    AgGridTable,
    BrunaElementLoader
  },

  directives: {
  },

  data: function () {
    return {
      cellValue: null,
    };
  },
  beforeMount() {
    // this.params contains the cell & row information and is made available to this component at creation time
    // see ICellRendererParams below for more details
    this.cellValue = this.getValueToDisplay(this.params);
    
  },
  methods: {
    goToDetail() {
          console.log( this.cellValue.data);
          //router.push({ path: `/sapdemo/plantaciones/clima/`+ this.cellValue.data.meta_data.id_sap });
          router.push({ path: `/data/plantaciones/clima`});

        },
    // gets called whenever the user gets the cell to refresh
    refresh(params) {
      // set value into cell again
      this.cellValue = this.getValueToDisplay(params);
    },

    getValueToDisplay(params) {
      return params;
      //return params.valueFormatted ? params.valueFormatted : params.value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/pages/ui-feather.scss";
</style>
